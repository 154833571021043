/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import * as Boxes from '../components/styles/blocks';
import {
  colors,
  fontSizes,
  lineHeight,
  size,
} from '../components/styles/variables';

const Layout = dynamic(() => import('../components/layoutComponent'));

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

export const Box = styled(Boxes.Block)`
  color: ${colors.darkPink};
  border: 2px solid ${colors.darkPink};
  border-radius: 20px;
  background-color: #040c1b;
  margin: 2.5rem auto;
  margin-top: 100px;
  padding: 20px 30px 20px 20px;
  max-width: min(700px, 75%);
  @media (max-width: ${size.md}) {
    padding: 10px 15px 10px 10px;
    max-width: min(700px, 85%);
  }
`;

const Title = styled.h1`
  color: ${colors.lightPink};
  line-height: 100%;
  text-align: center;
  font-size: ${fontSizes.medium};
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-top: 20px;

  @media (max-width: ${size.md}) {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: ${fontSizes.semiMedium};
  }
`;

const Text = styled.span`
  color: ${colors.fuchsia};
  letter-spacing: 0.10em;
  font-weight: bold;
  font-size: ${fontSizes.tagline};
  a {
    font-weight: bold;
    color: ${colors.lightPink};
    transition: 0.3s ease all;
    &:hover {
      color: ${colors.fuchsia};
    }
  }
  @media (max-width: ${size.md}) {
    font-size: ${fontSizes.news};
    line-height: ${lineHeight.normal};
  }
`;

export default function Pirates404() {
  const { t } = useTranslation('common');
  
  return (
    <Layout>
      <Boxes.Container>
        <Box>
          <Boxes.Block>
            <Title>
              Oops!
            </Title>
          </Boxes.Block>
          <Boxes.Block marginT="20px" marginB="20px">
            <Text>
              {t('404.message')}
            </Text>
          </Boxes.Block>
          <Boxes.Block>
            <Text>
              {t('404.return.text')}
              <Link href="/">
                <a>{t('404.return.page')}</a>
              </Link>
            </Text>
          </Boxes.Block>
        </Box>

      </Boxes.Container>
    </Layout>
  );
}
